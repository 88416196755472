.page--auth {
  min-height: 100vh;
  background: var(--app-neutral-0);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}


.logo--auth {
  margin-bottom: 80px;
  text-align: center;
}


.title--auth {
  margin-bottom: 32px;
  font-family: 'Poppins-Bold';
}


.paragraph--auth {
  color: var(--app-neutral-100);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
}


.form-wrapper--auth {
  width: 100%;
  max-width: 380px;
}


.label--auth {
  color: var(--app-neutral-80);
  font-weight: 700;
  margin-bottom: 8px;
}


.input--auth {
  background: none;
  border: none;
  border-bottom: 1px solid var(--app-neutral-100);
  color: var(--app-neutral-100);
  height: 40px;
  font-size: 24px;
  font-weight: bold;
}


.input--auth-password {
  letter-spacing: 4px;
}


.input--auth::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--app-neutral-0-opaque-15);
}
.input--auth::-moz-placeholder { /* Firefox 19+ */
  color: var(--app-neutral-0-opaque-15);
}
.input--auth:-ms-input-placeholder { /* IE 10+ */
  color: var(--app-neutral-0-opaque-15);
}
.input--auth:-moz-placeholder { /* Firefox 18- */
  color: var(--app-neutral-0-opaque-15);
}


.button--auth {
  width: 100%;
  display: block;
  border-color: var(--app-primary);
  background: var(--app-primary);
  color: var(--app-neutral-0);
  font-size: 20px;
}


.button--auth:hover {
  background: transparent;
  color: var(--app-neutral-0);
  background: var(--app-neutral-100);
}


.button--auth-inverted {
  border-color: var(--app-neutral-0);
  background: none;
  color: var(--app-neutral-0);
}


.button--auth-inverted:hover {
  background: var(--app-neutral-0);
  color: var(--app-button-text);
}


.form-footer--auth {
  margin-top: 32px;
}


.form-footer__link--auth {
  text-decoration: none;
  color: var(--app-primary);
  font-weight: 700;
  text-align: center;
  transition: all 0.25s ease-in-out;
}


.form-footer__link--auth:hover {
  color: var(--app-neutral-100);
}


.auth-back-button {
  position: absolute;
  top: 24px;
  left: 24px;
  cursor: pointer;
}


.auth-pins-wrapper {
  display: flex;
  justify-content: center;
}


.auth-pins {
  display: grid;
  grid-template-columns: repeat(6, 40px);
  grid-gap: 16px;
  text-align: center;
}

.auth-pin {
  width: auto;
  min-width: 40px;
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--app-neutral-100);
  color: var(--app-neutral-100);
  font-size: 64px;
}


.auth-pin--error {
  border-bottom: 3px solid #F48E8E;
}