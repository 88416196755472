.strong-password-meter {
  line-height: 12px;
  margin-top: 4px;
}


.strong-password-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 120px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  top: -3px;
  overflow: hidden;
}


.strong-password-meter-label {
  font-size: 12px;
  text-transform: uppercase;
}


.strong-password-meter-progress::-webkit-progress-bar {
  background-color: var(--app-neutral-0);
  border: 1px solid var(--app-neutral-5);
  border-radius: 4px;
}


.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 4px;
}


.strength-weak::-webkit-progress-value {
  background-color: var(--app-neutral-15);
}


.strength-strong::-webkit-progress-value {
  background-color: var(--app-primary);
}