.button {
  display: inline-block;
  background: none;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.25s ease-in-out;
  font-family: 'Poppins-Medium', sans-serif;
  text-decoration: none;
  padding: 16px;
  width: 100%;
  text-align: center;
}


@media screen and (min-width: 768px) {
  .button {
    width: auto;
  }
}


.button--large {
  font-size: 18px;
  border-radius: 8px;
  padding: 16px 32px;
}


.button--primary {
  background: var(--app-primary);
  color: var(--app-neutral-0);
}


.button--primary:hover {
  background: var(--app-neutral-100);
  color: var(--app-neutral-0);
}


.button--small {
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 16px;
}

.button-margin--top {
  margin-top: 16px;
}


.button-margin--bottom {
  margin-bottom: 16px;
}