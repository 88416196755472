.charging-timer__ball {
  display: block;
  width: 286px;
  height: 286px;
  border-radius: 50%;
  position: relative;
  margin: 16px auto 64px;
  text-align: center;
  background-color: #79ECB2;
  -webkit-box-shadow: 0px 0px 48px 0px rgba(121, 236, 178, 1);
  -moz-box-shadow: 0px 0px 48px 0px rgba(121, 236, 178, 1);
  box-shadow: 0px 0px 48px 0px rgba(121, 236, 178, 1);
  overflow: hidden;
}


.charging-timer__time {
  line-height: 420px;
  font-size: 52px;
  position: relative;
  z-index: 1
}


.charging-stats {
  display: flex;
  gap: 16px;
  margin: 16px 0;
}


.wave {
  background: url(../../../assets/wave.svg) repeat-x; 
  background-position: bottom;
  position: absolute;
  z-index: 0;
  bottom: -10px;
  width: 3600px;
  height: 286px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.5;
}


.wave:nth-of-type(2) {
  bottom: -50px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}


@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}


@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,25px,0);
  }
}