.box-navigation-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}


.box-navigation {
  background: var(--app-neutral-0);
  color: var(--app-neutral-100);
  display: block;
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
  padding: 16px;
  border: 3px solid transparent;
  text-decoration: none;
  padding: 16px;
  display: grid;
  grid-template-columns: 32px auto 7px;
  grid-gap: 8px;
}

@media screen and (min-width: 768px) {
  .box-navigation {
    padding: 32px;
    display: block;
    text-align: center;
  }
  .box-navigation__navigate {
    display: none;
  }
}


.box-navigation:hover {
  border-color: var(--app-primary);
  color: var(--app-neutral-100);
}


.box-navigation__icon {
  height: 24px;
}


.box-navigation__title {
  line-height: 24px;
}


@media screen and (min-width: 768px) {
  .box-navigation-group {
    grid-template-columns: 1fr 1fr;
  }
  .box-navigation__icon {
    margin-bottom: 16px;
  }
}


@media screen and (min-width: 1200px) {
  .box-navigation-group {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 1400px) {
  .box-navigation-group {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 1800px) {
  .box-navigation-group {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}