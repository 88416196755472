.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}


.toolbar__left-section {
  display: flex;
  gap: 16px;
}


.toolbar__right-section {
  display: flex;
  gap: 16px;
}


.toolbar h1 {
  font-size: 24px;
  line-height: 48px;
  font-family: 'Poppins-Medium', sans-serif;
  text-overflow: clip;
  width: 140px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.toolbar__notifications {
  background: var(--app-neutral-0);
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}


.toolbar__account {
  background: var(--app-neutral-0);
  display: block;
  height: 50px;
  border-radius: 8px;
  font-size: 14px;
  padding: 13px 16px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
}


.toolbar__menu-button {
  background: var(--app-primary);
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  padding: 16px 12px;
  cursor: pointer;
}


.menu-button__stripe {
  border: 1px solid var(--app-neutral-0);
  margin-bottom: 6px;
}


@media screen and (min-width: 430px) {
  .toolbar h1 {
    width: 200px;
  }
}

@media screen and (min-width: 600px) {
  .toolbar h1 {
    width: 320px;
  }
}


@media screen and (min-width: 768px) {
  .toolbar h1 {
    overflow: visible;
  }
}


@media screen and (min-width: 1200px) {
  .toolbar {
    display: flex;
    grid-gap: 16px;
  } 

  .toolbar__menu-button {
    display: none;
  }

  .toolbar h1 {
    font-size: 32px;
    line-height: 48px;
  }
  
}