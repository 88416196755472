.box-titlebar {
  margin-bottom: 16px;
}

.box-titlebar_buttons {
  display: inline-block;
  background: var(--app-primary-10);
  border-radius: 8px;
  margin-top: 16px;
}

.title-with-navigation {
  margin-left: 8px;
}


@media screen and (min-width: 600px) {
  .box-titlebar_buttons {
    display: flex;
    margin-top: 0;
  }
  .box-titlebar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}