.page__notfound {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.notfound__wrapper {
  padding: 32px 16px;
  text-align: center;
}


.notfound__wrapper h1 {
  font-size: 40px;
  line-height: 100px;
  font-family: 'Poppins-Medium', sans-serif;
  margin: 32px 0;
}


.notfound__wrapper p {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 16px;
}


.notfound__actions {
  margin-top: 64px;
  justify-content: center;
}

.notfound__actions > * {
  margin: 16px 0;
}


@media screen and (min-width: 768px) {

  .notfound__actions {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  .notfound__actions > * {
    margin: 0;
  }

  .notfound__wrapper h1 {
    font-size: 80px;
  }

}