.preloader {
  position: fixed;
  display: block;
  background: rgba(255,255,255,0.8);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


.preloader-small {
  position: relative;
  display: block;
  background: rgba(255,255,255,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}