.button-titlebar {
  display: inline-block;
  padding: 6px 16px;
  color: var(--app-primary);
  cursor: pointer;
  border-left: 1px solid var(--app-primary);
  text-decoration: none;
  transition: 0.3s;
}


.button-titlebar--selected,
.button-titlebar:hover,
.button-titlebar:focus {
  background: var(--app-primary);
  color: var(--app-neutral-0);
  border-radius: 8px;
  border-color: transparent;
}


.button-titlebar--selected + .button-titlebar,
.button-titlebar:hover + .button-titlebar {
  border-color: transparent;
}


.button-titlebar:first-child {
  border: none;
}


.button-titlebar--primary {
  background: var(--app-primary);
  color: var(--app-neutral-0);
  border-radius: 8px;
}


.button-titlebar--primary:hover {
  background: var(--app-neutral-100);
  color: var(--app-neutral-0);
}