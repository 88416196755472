@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url(../fonts/Poppins-Light.woff) format('woff');
}


@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(../fonts/Poppins-Medium.woff) format('woff');
}


@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(../fonts/Poppins-Bold.woff) format('woff');
}