.charging-timer__ball {
  display: block;
  width: 286px;
  height: 286px;
  border-radius: 50%;
  position: relative;
  margin: 16px auto 64px;
  text-align: center;
  background-color: #79ECB2;
  -webkit-box-shadow: 0px 0px 48px 0px rgba(121, 236, 178, 1);
  -moz-box-shadow: 0px 0px 48px 0px rgba(121, 236, 178, 1);
  box-shadow: 0px 0px 48px 0px rgba(121, 236, 178, 1);
  overflow: hidden;
}


.charging-timer__time {
  line-height: 420px;
  font-size: 52px;
  position: relative;
  z-index: 1
}


.charging-stats {
  display: flex;
  gap: 16px;
  margin: 16px 0;
}


/*

.wave {
  background: url(../../../assets/wave.svg) repeat-x; 
  background-position: bottom;
  position: absolute;
  z-index: 0;
  bottom: -10px;
  width: 3600px;
  height: 286px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.5;
}


.wave:nth-of-type(2) {
  bottom: -50px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}


@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}


@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,25px,0);
  }
}

*/



.dashboard-section {
  margin-bottom: 16px;
}


@media screen and (min-width: 1440px) {
  .dashboard {
    display: grid;
    gap: 16px;
    grid-template-columns: 3fr 1fr;
  }
}


.dashboard-chart {
  margin-top: 32px;
}



@media screen and (min-width: 900px) {
  .dashboard-charts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .dashboard-chart {
    margin-bottom: 0;
  }
}




@media screen and (min-width: 800px) {
  .charger-settings {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}


@media screen and (min-width: 1400px) {
  .charger-settings {
    display: grid;
    grid-template-columns: 1fr;
  }
}


.charger-settings__section-title {
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 16px;
}


.charger-settings__section {
  border-bottom: 1px solid var(--app-neutral-20);
  padding: 16px 0;
}

.charger-settings__section:last-child {
  border: none;
}


@media screen and (min-width: 800px) {
  .charger-settings__section {
    border-bottom: none;
    padding: 24px;
  }

  .charger-settings__section:first-child {
    border-bottom: 1px solid var(--app-neutral-20);
    padding-top: 0;
  }

  .charger-settings__section:last-child {
    padding-bottom: 0;
  }

  .charger-settings__section--map {
    border-left: 1px solid var(--app-neutral-20);
    margin-left: 16px;
  }

  .charger-settings__section--general-token {
    border-left: 1px solid var(--app-neutral-20);
    margin-left: 16px;
  }

  .charger-settings__section--general-token {
    order: 4;
  }
}


@media screen and (min-width: 1400px) {
  .charger-settings__section {
    border: none;
    border-bottom: 1px solid var(--app-neutral-20);
    padding: 16px 0;
    margin: 0;
  }

  .charger-settings__section:last-child {
    border: none;
  }

  .charger-settings__section--general-token {
    order: unset;
  }
  
}




.charger-settings__item {
  margin-bottom: 8px;
}


.charger-settings__item a {
  color: var(--app-primary);
  font-family: 'Poppins-Medium', sans-serif;
}


.charger-settings__item:last-child {
  margin-bottom: 0;
}


.generate-token {
  background: var(--app-primary-10);
  padding: 16px;
  border-radius: 8px;
}



.charger-settings__title-label {
  padding: 8px 16px;
  color: var(--app-neutral-0);
  border-radius: 4px;
  font-family: 'Poppins-Medium', sans-serif;
}