.language-selection select {
  border: none;
  background: transparent;
  color: var(--app-neutral-100);
  font-size: 24px;
}


.language-selection--auth {
  position: absolute;
  top: 32px;
  right: 32px;
}