/* @GENERAL RULES */
html, body, #root, .page {
  height: 100%;
}

body {
  background: var(--app-neutral-10);
  color: var(--app-neutral-100);
  font-family: 'Poppins-Light', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}


body * {
  box-sizing: border-box;
  /* -webkit-appearance: none; */
  border-radius: 0;
}

body *:focus {
  outline: 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--app-font-50);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--app-font-50);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--app-font-50);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--app-font-50);
}

a, a:active, a:focus {
  color: var(--app-font-50);
}

a:hover {
  color: var(--app-font-50);
}

em, strong {
  font-family: 'Poppins-Medium';
}

sup { 
  vertical-align: super;
  font-size: smaller;
}

small {
  font-size: 12px;
  color: var(--app-font-50);
}

img {
  display: block;
}

h1{
  font-size: 32px;
  line-height: 38px;
  font-weight: normal;
}

h2{
  font-size: 24px;
  line-height: 30px;
  font-weight: normal;
}

h3{
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

.center {
  text-align: center;
}


.paragraph {
  display: block;
}


.paragraph--medium {
  font-size: 20px;
  line-height: 32px;
}

.paragraph--large {
  font-size: 24px;
  line-height: 40px;
}

.paragraph--highlight {
  padding: 8px 16px;
  background-color: var(--app-forms-input-disabled);
  border-radius: 8px;
}

.paragraph--margin-medium {
  margin-bottom: 16px;
}

.paragraph--margin-large {
  margin-bottom: 32px; 
}