.shared__navigation {
  position: fixed;
  top: 0;
  left: -100%;
  width: var(--app-menu-width);
  min-height: 100vh;
  background: var(--app-neutral-0);
  transition: left 0.3s ease-in-out;
  z-index: 999;
}


.shared__navigation.open {
  left: 0;
}


.navigation__logo-warpper {
  display: block;
  margin: 16px 24px 32px;
  text-align: center;
  border-bottom: 1px solid var(--app-neutral-5);
}


.navigation__main-wrapper {
  display: block;
  margin: 40px 24px 80px;
}


.navigation__tools-wrapper {
  display: block;
  margin: 40px 24px;
}


.navigation__version {
  display: block;
  padding: 8px 0;
  margin: 0 24px;
  text-align: center;
  font-size: 12px;
  color: var(--app-font-100);
  border-top: 1px solid var(--app-neutral-5);
}


.navigation__item {
  text-align: left;
  margin-bottom: 16px;
}


.navigation__item-text {
  display: inline-block;
  font-size: 16px;
}


.navigation__item-link {
  text-decoration: none;
  display: block;
  padding: 8px 16px;
  color: var(--app-font-100);
  font-family: 'Poppins-Medium', sans-serif;
  transition: all 0.25s ease-in-out;
  line-height: 32px;
  text-align: left;
  border-radius: 8px;
}


.navigation__item-link:hover,
.navigation__item-link.active {
  background: var(--app-primary-10);
  color: var(--app-primary);
}


.navigation__item-link:hover svg g,
.navigation__item-link.active svg g {
  fill: var(--app-primary);
}


.navigation__item-link .app-icon {
  margin-right: 24px;
  position: relative;
  bottom: -6px;
}


.navigation__close {
  display: block;
  width: 40px;
  height: 40px;
  background: var(--app-primary);
  border-radius: 50%;
  position: absolute;
  top: 84px;
  right: -20px;
  cursor: pointer;
  line-height: 48px;
}


@media screen and (min-width: 1200px) {
  .shared__navigation {
    position: relative;
    left: 0;
  }

  .navigation__close {
    display: none;
  }
}