/* @RESPONSIVE TABLE */
.table {
  width: 100%;
  display: table;
}


@media screen and (max-width: 768px) {
  .table {
    display: block;
    width: 100%;
  }
}


.table .row {
  display: table-row;
  background: var(--app-neutral-0);
}


.table .row:nth-of-type(odd) {
  background: var(--app-primary-05);
}


.table .row .cell {
  border-bottom: 1px solid var(--app-neutral-20);
  font-size: 18px;
  padding: 16px;
  vertical-align: middle;
}


@media screen and (min-width: 768px) {
  .table .row .cell {
    font-size: 14px;
  }
}


.table .row.header .cell {
  background: var(--app-neutral-10);
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 14px;
  padding: 16px;
  text-align: left;
}


.table .row.header .cell:first-child {
  border-top-left-radius: 16px;
}


.table .row.header .cell:last-child {
  border-top-right-radius: 16px;
}


@media screen and (max-width: 768px) {

  .table .row {
    display: block;
    width: 100%;
  }


  .table .row:nth-of-type(even) {
    background: var(--app-neutral-10);
  }
  

  .table .row.header {
    padding: 0;
    height: 6px;
  }


  .table .row.header .cell {
    display: none;
  }


  .table .row .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }

}


.cell {
  padding: 6px 12px;
  display: table-cell;
}


.cell.cell-icon {
  width: 16px;
}


.cell.cell-icon.cell-icon--clickable {
  cursor: pointer;
}


.cell.cell-button {
  width: 24px;
}


@media screen and (max-width: 768px) {

  .cell {
    padding: 2px 16px;
    display: block;
  }


  .row .cell.empty {  
    display: none;
  }

}





/* @TABLEICONS */
.dashboard-navigation-icon {
  background: var(--app-primary);
  border: 1px solid var(--app-primary-darker);
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  transition: 0.25s;
}


.dashboard-navigation-icon:hover {
  background: var(--app-neutral-100);
  border: 1px solid var(--app-neutral-100);
}


@media screen and (min-width: 768px) {
  .dashboard-navigation-icon {
    position: relative;
  }
}


.table-identity-icon {
  position: relative;
  bottom: -2px;
  margin-right: 8px;
}


/* @TABLEBUTTONS */
.table-button {
  background: var(--app-primary);
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  border: 1px solid var(--app-primary-darker);
  margin-right: 8px;
  transition: all 0.25s ease;
}


.table-button:hover {
  background: var(--app-neutral-80);
  border-color: var(--app-neutral-100);
}


/* @LABELS */
.table-label {
  display: inline-block;
  position: relative;
}


.table-label.table-label--circle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  bottom: -3px;
  background-color: var(--app-neutral-20);
}


.table-label.table-label--circle.table-label--trafic-go {
  background-color: var(--app-success);
}


.table-label.table-label--circle.table-label--trafic-stop {
  background-color: var(--app-error);
}


.table-label.table-label--text {
  padding: 2px 8px;
  border-radius: 4px;
  color: var(--app-neutral-0);
  background-color: var(--app-neutral-100);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  top: -1px;
}


.table-label.table-label--text.table-label--bg-default {
  color: var(--app-neutral-100);
  background-color: var(--app-neutral-40);
}


.table-label.table-label--text.table-label--bg-custom {
  color: var(--app-neutral-100);
  background-color: var(--app-neutral-20);
}


.app-icon-more {
  top: 8px;
}