.template__page--with-navigation .page {
  min-height: 100vh;
  padding: 16px;
}


@media screen and (min-width: 768px) {
  .template__page--with-navigation .page {
    padding: 32px;
  }
}


@media screen and (min-width: 1200px) {
  .template__page--with-navigation {
    display: grid;
    grid-template-columns: var(--app-menu-width) auto;
  }
}


.toolbar__return-button {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
  display: none;
}


@media screen and (min-width: 768px) {
  .toolbar__return-button {
    display: block;
  }
}