:root {

  /* @APP COLOR */
  --app-primary: #17B566;
  --app-primary-darker: #169756;
  --app-primary-05: rgba(23, 181, 102, 0.05);
  --app-primary-10: rgba(23, 181, 102, 0.1);
  --app-primary-30: rgba(23, 181, 102, 0.3);


  /* @APP NEUTRALS */
  --app-neutral-0: #ffffff;
  --app-neutral-5: #f5f5f5;
  --app-neutral-10: #f7f8fd;
  --app-neutral-20: #e5e5e5;
  --app-neutral-40: #ACACAC;
  --app-neutral-0-opaque-15: rgba(255, 255, 255, 0.3);
  --app-neutral-80: #1C1F27;
  --app-neutral-90: #060707;
  --app-neutral-100: #000;


  /* @APP RANDOM */
  --app-box-shadow: 0px 0px 8px 8px #eee;


  /* @APP FORMS */
  --app-forms-input-disabled: #dbdde7;
  --app-forms-input: #000;
  --app-forms-input-focus: #000;
  --app-forms-input-error: #F48E8E;


  /* @APP FONTS */
  --app-font-100: #000000;
  --app-font-15: #ACACAC;
  --app-font-10: #f7f8fd;


  /* @APP MESSAGES */
  --app-success: green;
  --app-error: red;


    /* @APP MENU WIDTH */
  --app-menu-width: 240px;
  
}


