.box {
  display: block;
  background: var(--app-neutral-0);
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
  margin-bottom: 16px;
  border: 3px solid transparent;
  padding: 16px;
}


.box:last-child {
  margin-bottom: 0;
}


.box h2 {
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 20px;
  line-height: 36px;
}


@media screen and (min-width: 768px) {
  .box {
    padding: 16px;
    margin-bottom: 16px;
  }
}