.navigation {
  display: inline-block;
  background: var(--app-primary);
  border: 1px solid var(--app-primary);
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  text-align: center;
}


.navigation--disabled {
  background: var(--app-neutral-10);
  border: 1px solid var(--app-neutral-10);
  cursor: default;
}