.app-offline-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(255,255,255,0);
  transition: all 1s ease-in-out;
  align-items: center;
  justify-content: center;
}


.app-offline-overlay--visible {
  display: flex;
  background: rgba(255,255,255,0.9);
}


.app-offline__content {
  padding: 16px;
  text-align: center;
}


.app-offline__title {
  font-size: 32px;
  margin-bottom: 24px;
}

.app-offline__text {
  font-size: 18px;
  margin-bottom: 24px;
}