.label {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-family: 'Poppins-Medium';
  color: var(--app-font);
}


.label.label--error {
  background: var(--app-forms-input-error);
  padding: 4px 16px;
  border-radius: 50px;
  display: inline-block;
}


.label.label--error .app-icon--warning {
  position: relative;
  bottom: -2px;
  margin-right: 8px;
}


.input,
.select {
  transition: all 0.25s ease-in-out;
  color: var(--app-forms-input);
  font-family: 'Poppins-Medium', sans-serif;
  background: none;
  border: none;
  width: 100%;
}


.input.input--form,
.select.select--form {
  font-size: 18px;
  padding: 8px 2px;
  border-bottom: 2px solid var(--app-forms-input);
}


.input.input--form:disabled,
.select.select--form:disabled {
  color: var(--app-forms-input-disabled);
  border-bottom: 2px solid var(--app-forms-input-disabled);
}


.input.input--form:focus,
.select.select--form:focus {
  border-color: var(--app-forms-input-focus);
}


.input.input--error,
.select.select--error {
  border-bottom: 3px solid var(--app-forms-input-error);
}


.input-group {
  position: relative;
  margin-top: 8px;
  width: 100%;
}


@media screen and (min-width: 768px) {
  .input-group {
    max-width: 800px;
  }
}


.form-group {
  margin-bottom: 40px;
  position: relative;
}


.form-group:last-child {
  margin-bottom: 0;
}


.form-section {
  margin-bottom: 32px;
}


.form-section:last-child {
  margin-bottom: 0;
}


.form-section__title {
  margin-bottom: 16px;
}


.form-footer__link {
  text-transform: uppercase;
}


.password-reveal {
  position: absolute;
  right: 0;
  top: 8px;
  cursor: pointer;
  z-index: 1;
}


.form-error {
  animation: shake 0.2s ease-in-out 0s 2;
}


@keyframes shake {
  0% {
    margin-left: 0rem;
    margin-right: 0 rem;
  }
  25% {
    margin-left: 32px;
    margin-right: -32px;
  }
  75% {
    margin-left: -32px;
    margin-right: 32px;
  }
  100% {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}